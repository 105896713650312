//
// _dashboard.scss
//

.dash-filter-picker {
  min-width: 210px !important;
}

// Flatpickr Calendar

.upcoming-scheduled {
  position: relative;
  @media (max-width: 575.98px) {
    top: 35px !important;
  }
  .flatpickr-months {
    position: absolute !important;
    top: -45px !important;
    left: auto !important;
    right: 0px !important;
    width: 200px;
    background-color: transparent;

    .flatpickr-month {
      color: $gray-600 !important;
      fill: $gray-600 !important;
    }
    .flatpickr-prev-month,
    .flatpickr-next-month {
      display: none;
    }
  }
  .flatpickr-calendar {
    box-shadow: none !important;
    .flatpickr-current-month {
      font-size: 13px;
      width: 100%;
      left: 0;
    }
    .flatpickr-monthDropdown-months {
      border: 1px solid $border-color;
      border-radius: 4px;
      height: 26px;
    }
    .flatpickr-weekdays {
      background-color: var(--#{$prefix}light);
      border: none;
      span.flatpickr-weekday {
        color: var(--#{$prefix}dark);
        background-color: var(--#{$prefix}light);
      }
    }
    .flatpickr-day {
      &.today {
        color: $white !important;
        background-color: $success;
        border-color: $success !important;
        &:hover {
          color: $success !important;
          background-color: rgba($success, 0.2) !important;
        }
      }
      &.selected {
        background-color: $success !important;
        border-color: $success !important;
        color: $white;
      }
    }
    .numInputWrapper {
      width: 7.5ch;
      margin-left: 10px;
    }
  }

  .flatpickr-days {
    border: none !important;
  }
}

.crm-widget {
  .col {
    border-right: 1px solid $border-color;
    &:last-child {
      border: 0px;
    }
    @media (min-width: 768px) and (max-width: 1399.98px) {
      &:nth-child(3) {
        border-right: 0px;
      }
      &:last-child {
        border-right: 1px solid $border-color;
      }
    }
    @media (max-width: 767.98px) {
      border-right: 0px;
      border-bottom: 1px solid $border-color;
    }
  }
}

@media (min-width: 1400px) and (max-width: 1599.98px) {
  .project-wrapper {
    > .col-xxl-8,
    .col-xxl-4 {
      width: 100%;
    }
  }
}

.crypto-widget {
  max-width: 130px !important;
}

//nft dashboard

.bg-marketplace {
  background-image: url(../../images/nft/marketplace.png);
  background-size: cover;
}

.dash-countdown {
  .countdownlist {
    .count-num {
      background-color: var(--#{$prefix}card-bg-custom);
      padding: 16px 8px;
      font-size: 22px;

      @media (max-width: 575.98px) {
        font-size: 16px;
        padding: 8px 6px;
      }
    }

    .count-title {
      @media (max-width: 575.98px) {
        font-size: 10px;
      }
    }
  }
}

.marketplace-icon {
  position: absolute;
  float: right;
  top: 30px;
  left: 30px;
}

.marketplace-swiper {
  .swiper-button-next,
  .swiper-button-prev {
    top: 34px;
    width: 28px;
    height: 28px;
    background-color: rgba($primary, 0.1);
    color: $primary;
    border-radius: 0.3rem;
    right: 16px !important;
  }
  .swiper-button-prev {
    right: 58px !important;
    left: auto !important;
  }
}

.dash-collection {
  .content {
    background-color: rgba($white, 0.25);
    backdrop-filter: blur(5px);
  }
}

.dash-nft {
  @media (max-width: 1441.98px) {
    .col-xxl-9,
    .col-xxl-3 {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}

.jvectormap-legend-cnt-h .jvectormap-legend-tick-sample {
  width: 32px;
  height: 32px;
  display: inline-block;
  vertical-align: middle;
}

.reports-events-container {
  .block {
    background: $purple;
    color: $white;
    text-align: center;
    border-radius: 4px;

    i {
      padding: 10px;
      border-radius: 4px;
      background: $light-bg;
      color: $white;
    }
  }

  .btn {
    background: $white;
    border: none;

    &:hover {
      color: $purple;
    }
  }
}

.new-users-filter-date {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(56, 65, 74, 0.15);

  input {
    text-align: center;
    padding: 5px;
    border: none;
  }
}

.text-light-purple {
  background: $light-blue !important;
  color: $purple !important;
}

.text-purple {
  color: $purple !important;
}

.schedule-breakdown-container {
  .card-body {
    min-height: 245px;
  }
}

.pending-users-card {
  max-width: 740px;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
}

.notifications-list {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  height: 97%;
  z-index: 1;

  .gridjs-summary {
    margin-left: 15px;
  }

  .gridjs-pages {
    margin-top: 15px;
    margin-bottom: 20px;
    float: left;
  }
}

.slide-tabs-container {
  flex-wrap: wrap;
  width: 100%;
  overflow-x: hidden;

  @media (min-width: 1700px) {
    div {
      flex: 0 0 calc(33.33% - 1rem);
    }
  }
}

.slide-tab-arrow {
  border: 1px solid #dde2e3;
  top: 15px;
  border-radius: 50%;
  position: absolute;
  background: $white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
}

.no-chart-data {
  position: absolute;
  top: 45%;
  left: 45%;
}

#dashboard-not-registered-users,
#dashboard-import-logs,
#dashboard-annual-education-overdue {
  margin: -1rem -1rem;
  max-height: 560px;
  overflow-y: auto;
  th {
    background: $white !important;
  }
  .table-card {
    margin: 0;
  }
}

.scroll-not-visible {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.notifications-container {
  min-height: 71vh;

  .notification-item {
    h6 {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}

.card-animate {
  height: calc(100% - 25px);
}

.import-error-note {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.new-users-container {
  height: 47%;
}

#users-not-registered-table {
  table-layout: fixed;
}
