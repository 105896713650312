.filters-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  @media (max-width: 1910px) {
    &.course-history-filters .filter-col {
      flex: 0 0 calc(25% - 2rem);
    }
  }

  @media (max-width: 1710px) {
    .filter-col {
      flex: 0 0 calc(25% - 2rem);
    }
  }

  @media (max-width: 1024px) {
    .filter-col {
      flex: 0 0 calc(30% - 1rem);
    }
  }

  @media (max-width: 768px) {
    .filter-col {
      flex: 0 0 calc(50% - 1rem);
    }
  }

  @media (max-width: 576px) {
    .filter-col {
      flex: 0 0 calc(100% - 1rem);
    }
  }
}

.user-report-filter-row {
  @media (min-width: 1180px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr) !important;
  }

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
