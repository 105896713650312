$primary-color: #0039c7;
$gray-color: #d1d5db;
$toggle-size: 40px;
$circle-size: 12px;

.toggle {
  width: $toggle-size;
  height: 20px;
  padding: 4px;
  background: $gray-color;
  border-radius: 999px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background 0.3s ease-in-out;

  &.enabled {
    background: $primary-color;
  }

  .toggle-circle {
    width: $circle-size;
    height: $circle-size;
    background: white;
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
  }

  &.enabled .toggle-circle {
    transform: translateX(calc($toggle-size - $circle-size - 8px));
  }
}
